$(document).on('turbolinks:load', function() {
  $("#card_psa_grade").on('change', function() {
  })
})


function validateNumericality(element, max = false) {

  let value = element.value.replace(/[^0-9.]/g, '')
  if( !max ){
    value = value.slice(0, max);

  }
  console.log(value);
  element.value = value;
}
