// Call the dataTables jQuery plugin
let dataTable = null;
$(document).on('turbolinks:load', function() {
  if (!dataTable) {
    dataTable = $('#dataTable').DataTable({
      destroy: true,
      searching: false,
      select: false,
      paginate: false,
      info: false,
    });
  }
  $("th[data-orderable='false']").prop('class', 'sorting_disabled')
});

$(document).on('turbolinks:before-cache', function() {
  if (dataTable) {
    dataTable.destroy();
    dataTable = null;
  }
});
